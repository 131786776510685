/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSession } from "../session/session";
import { useStoreState } from "../../store";

export enum SenderType {
  User = 0,
  Admin = 1,
}

export type MessageType =
  | "chat"
  | "tournament"
  | "matchUpdated"
  | "proofAdded"
  | "srm";
export type MessageListener = (room: string, data: EventMessage) => void;

export interface ChatMessage {
  message: string;
  id: string;
}

export interface EventMessage {
  room: string;
  target: string;
  type: MessageType;
  content: any;
  sender: string;
  senderType: SenderType;
  senderName: string;
  date: string;
}

export interface EventSubscribe {
  id: string;
  rt: boolean;
}

export interface EventSend {
  target: string;
  type: MessageType;
  content: any;
}

interface IWebSocketContext {
  socket: WebSocket | null;
  subscribe: (
    type: MessageType,
    room: string,
    callback: MessageListener
  ) => void;
  unsubscribe: (type: MessageType, room: string) => void;
  sendMessage: (
    reason: MessageType,
    roomId: string,
    message: string
  ) => EventMessage | null;
}

const WebSocketContext = createContext<IWebSocketContext>(
  {} as IWebSocketContext
);

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

type CallbacksByAction = Map<MessageType, Callback>;
type Callback = Map<string, MessageListener>;

export const WebSocketProvider = (props: any) => {
  const idToken = useSession().data?.getSignInUserSession()?.getIdToken();
  const user = useStoreState((s) => s.login.user);
  const socketUrl = String(process.env.REACT_APP_WEB_SOCKET_URL);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  let pingInterval = useRef<NodeJS.Timeout>();
  const callbacksByAction = useRef<CallbacksByAction>(new Map());

  const bufferedJoins: Array<string> = [];
  const joinedRooms: { [roomId: string]: number } = {};

  const ping = useCallback(() => {
    if (!socket || socket.readyState !== WebSocket.OPEN) {
      return;
    }
    socket.send(JSON.stringify({ action: "ping" }));
  }, [socket]);

  const onOpen = useCallback(() => {
    if (!socket) {
      return;
    }
    //console.log('open', socket.readyState);
    clearInterval(pingInterval.current);
    pingInterval.current = setInterval(() => ping(), 2 * 60 * 1000);
    //console.log(pingInterval);
    while (bufferedJoins.length > 0) {
      const room = bufferedJoins.pop();
      if (!room) {
        break;
      }

      joinRoom(room);
    }
  }, [ping]);

  const joinRoom = (room: string) => {
    if (!socket) {
      return;
    }
    if (joinedRooms[room]) {
      return;
    }
    if (socket.readyState !== WebSocket.OPEN) {
      bufferedJoins.push(room);
    } else {
      socket.send(
        JSON.stringify({
          action: "join",
          room: room,
        })
      );
      if (joinedRooms[room]) {
        joinedRooms[room]++;
      } else {
        joinedRooms[room] = 1;
      }
    }
  };

  const subscribe = (
    type: MessageType,
    room: string,
    callback: MessageListener
  ): void => {
    //console.log('websocket subscribe start :', type, room);
    let action = callbacksByAction.current.get(type);
    if (!action) {
      action = new Map();
    }
    if (!action.has(room)) {
      joinRoom(room);
    }
    action.set(room, callback);
    callbacksByAction.current.set(type, action);
    //console.log('websocket subscribe end', callbacksByAction);
  };

  const unsubscribe = (type: MessageType, room: string): void => {
    const callbacks = callbacksByAction.current.get(type);
    if (!callbacks) {
      return;
    }

    callbacks.delete(room);
    if (joinedRooms[room]) {
      joinedRooms[room]--;
      if (joinedRooms[room] <= 0) {
        delete joinedRooms[room];
      }
    }
    if (callbacks.size <= 0) {
      callbacksByAction.current.delete(type);
    }
  };

  const connectWebSocket = () => {
    if (socket !== null && socket.readyState < WebSocket.CLOSING) {
      return;
    }

    if (!idToken) {
      setSocket(null);
      return;
    }

    if (socket !== null) {
      socket.close();
    }

    const newSocket = new WebSocket(
      `${socketUrl}?token=${idToken.getJwtToken()}`
    );
    setSocket(newSocket);
  };

  useEffect(() => {
    if (!idToken) {
      return;
    }
    connectWebSocket();
  }, [socketUrl, idToken]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.onclose = () => {
      console.log("WS closed");
    };

    socket.onopen = () => {
      onOpen();
    };

    socket.onerror = (error) => {
      console.log("WS Error : ", error);
    };

    socket.onmessage = (event) => {
      onMessage(event);
    };

    return () => socket.close();
  }, [socket]);

  const sendMessage = (
    reason: MessageType,
    roomId: string,
    messageContent: any
  ): EventMessage | null => {
    if (!socket || socket.readyState !== WebSocket.OPEN || !user) {
      return null;
    }
    const msg: EventMessage = {
      room: roomId,
      target: "",
      type: reason,
      content: messageContent,
      sender: user.identity.id,
      senderType: SenderType.User,
      senderName: `${user.identity.nickname}#${user.identity.code}`,
      date: new Date().toISOString(),
    };
    socket.send(JSON.stringify({ ...msg, action: "event" }));
    return msg;
  };

  const onMessage = (messageEvent: MessageEvent) => {
    if (!messageEvent?.data) {
      return;
    }
    const message = JSON.parse(messageEvent.data) as EventMessage;
    //console.log('onMessage', message, callbacksByAction);
    const { type, room } = message;
    const callbacks = callbacksByAction.current.get(type);
    const srmCallbacks = callbacksByAction.current.get("srm");

    if (!callbacks && !srmCallbacks) {
      return;
    }
    if (callbacks) {
      const listener = callbacks.get(room);
      if (!listener) {
        return;
      }
      listener(room, message);
    }
    if (srmCallbacks) {
      const listener = srmCallbacks.get(room);
      if (!listener) {
        return;
      }
      listener(room, message);
    }
  };

  return (
    <WebSocketContext.Provider
      value={{
        socket,
        subscribe,
        unsubscribe,
        sendMessage,
      }}
    >
      {props.children}
    </WebSocketContext.Provider>
  );
};
